import React, { useState, useEffect } from 'react'
import RecoveryPasswordModal from '../RecoveryPasswordModal'
import { forgetPassword } from '../../Constants/Api'
import OnBoardCarousel from '../../../../Components/OnBoardCarousel'
import VerifyModal from "../VerifyModal"
import Loader2 from '../../../../Components/LoaderHalf'
import ResetPasswordModal from '../ResetPasswordModal'
import { getOtpForVerifcation } from '../../Constants/Api';
// import { alertMsg } from '../../../../Components/DecesionComponent/toastifyAlert'
import {message} from "antd";
import { ToastContainer, toast } from 'react-toastify'

const ForgetPassword = () => {

    const [formData, setFormData] = useState({
        email: '',
    })
    const [otp, setOtp] = useState('')
    const [isDisabled, setIsDisabled] = useState(false)

    const handleOtp = (e) => {
        setOtp(e.target.value)
    }
    const [isError, setIsError] = useState(false)
    const [isVerify, setIsVerify] = useState({
        verify: false,
        verified: false
    })
    const [isLoading, setIsLoading] = useState(false)

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const { email } = formData

    const handleVerified = () => {
        setIsVerify({ ...isVerify, verified: true })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (email === '') {
            return message.error("Enter your registered Email.");
        }
        try {
            // setIsLoading(true)
            await forgetPassword({
                email: email
            })
            setIsVerify({ ...isVerify, verify: true })
        }
        catch (e) {
            if(e.response.data.error=="User not found with this email"){
                // message.error("User not found.")
                toast.error("User not found with this email")
              }
            setIsError(true)
        } finally {
            setIsLoading(false);
        }
    }

    const handleOtpVerification = async () => {
        setIsLoading(true);
        try {
            await getOtpForVerifcation({
                email: email,
                otp: otp,
                type: 'BUSINESS'
            });
            setIsVerify({ ...isVerify, verified: true });
            setIsLoading(false);
        } catch (error) {
            message.error('Invalid OTP.')
            setIsLoading(false);
        }
    };

    return (<>
    <ToastContainer />
        <div className='login-main forget-password-wrapper'>
            <OnBoardCarousel />
            {isLoading ? <Loader2 /> : (
                <>
                    {!isVerify.verify ? (
                        <RecoveryPasswordModal
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                        />
                    ) : (
                        !isVerify.verified ? <VerifyModal
                            setIsVerify={setIsVerify}
                            recoveryEmail={formData?.email}
                            isLoading={isLoading}
                            handleOtp={handleOtp}
                            otp={formData?.otp}
                            handleConfirmOtp={handleOtpVerification}
                            isDisabled={isDisabled}
                            setIsDisabled={setIsDisabled}
                        /> : isVerify.verified && <ResetPasswordModal
                            formData={formData}
                            otp={otp}
                            isLoading={isLoading}
                            handleChange={handleChange}
                        />
                    )}
                </>
            )}
        </div>
    </>)
}

export default ForgetPassword
