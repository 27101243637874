import axios from "axios";
// import { authBaseDomain, stage } from "../../Constants/authApis";
import { authBaseDomain, stage } from "../../../../Constants/authApis";

export const signUpFirstNameLastNameEmailApi = async (payload) => {
  return axios.post(
    `${authBaseDomain}${stage}/auth/onboardBusinessSelf`,payload
  );
};

export const signUpVerifyOtp = (payload) => {
  return axios.post(
    `${authBaseDomain}${stage}/auth/verify-otp`,payload
  )};

export const signUpSetPassword = (payload) => {
  const signUpToken = sessionStorage.getItem("signUpToken");
  const headers = {
    Authorization: `Bearer ${signUpToken}`,
    "Content-Type": "application/json",
  };
  console.log(payload);
  if (headers) {
    return axios.post(
      `${authBaseDomain}${stage}/auth/BusinessAddPassword`,payload,{ headers }
      )}
};

export const signUpCompanyDeatilsApi = (payload) => {
  console.log(payload);
  const signUpToken = sessionStorage.getItem("signUpToken");
  const headers = {
    Authorization: `Bearer ${signUpToken}`,
    "Content-Type": "application/json",
  };
  return axios.put(
    `${authBaseDomain}${stage}/auth/addOrganizationDetails`,
    payload,
    { headers }
  );
};

export const signUpNumberUsers = (payload) => {
  console.log(payload);
  const signUpToken = sessionStorage.getItem("signUpToken");
  const headers = {
    Authorization: `Bearer ${signUpToken}`,
    "Content-Type": "application/json",
  };
  return axios.post(
    `${authBaseDomain}${stage}/payment/order/pack`,payload,{ headers }
  );
};
