
import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify'
import Loader from '../../../../Components/Loader';
import { forgetPassword } from '../../Constants/Api';

const VerifyModal = ({ setIsVerify, handleOtp, isDisabled, setIsDisabled, recoveryEmail, handleConfirmOtp, otp }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(120);

  useEffect(() => {
    if (timer === 0) {
      setIsDisabled(false);
    }
  }, [timer]);

  useEffect(() => {
    if (timer > 0 && !isDisabled) {
      const countdown = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [timer, isDisabled]);

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  const handleResendOtp = async () => {
    await forgetPassword({
      email: recoveryEmail
    });
    setTimer(120);
  }


  const emailAddress = 'email address';
  return (
    <>
      <div className='login-form'>
        <h1>Verify it’s you</h1>
        <p>
          We’ve sent an OTP to your {emailAddress}. Incorrect email? &nbsp;
          <span onClick={() => setIsVerify(false)} className='clickME'>Click here</span>
        </p>
        <form>
          <div className='form-floating mb-3 mt-3'>
            <input
              type='text'
              className='form-control'
              id='otp'
              placeholder='Enter OTP'
              name='otp'
              // value={otp}
              onChange={handleOtp}
              autoComplete='off'
              isLoading={isLoading}
            />
            {/* <ToastContainer /> */}
            <label htmlFor='otp' className='form-label'>
              Enter OTP
            </label>
          </div>
          {timer === 0 ? (

            <p className='resendOtp' onClick={handleResendOtp} disabled={!isDisabled} style={{marginLeft:'30px'}}>
              Resend OTP 
            </p>
          ) : (
            <p className='resendOtpAgain' disabled style={{marginLeft:'30px'}}>
              Resend OTP {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
            </p>
          )}

          <button type="cnf-otp" onClick={() => handleConfirmOtp()} style={{padding:"14px",backgroundColor:"#1C3879",color:"white",borderRadius: "8px",cursor:"pointer",fontWeight:"600",width:"400px"}} >

            Confirm OTP
          </button>
        </form>
      </div>
      {/* width: 360px;
    height: 56px;
    border: none;
    background-color: #1C3879;
    color: #fff;
    border-radius: 8px;
    padding: 16px;
    font-weight: 600;
    margin-top: 20px; */}
    </>
  );
};

export default VerifyModal;