import React, { useState } from "react";
// import NavbarComp from '../../Components/NavbarComp'
// import { assets } from '../../assets/assets'
import { Link, useNavigate } from "react-router-dom";
// import { loginApi } from "./api";
// import { ToastContainer,toast } from "react-toastify";
// import { alertMsg } from '../../Components/toastifyAlert'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import Loader from '../../Components/Loader'
import "./style.scss";
// import FooterComp from '../../Components/FooterComp'
import { useDispatch } from "react-redux";

// import GoogleAuthLogin from '../../Components/GoogleComp'

// import {
//   loginUserAction,
//   googleUserData,
// } from "../../Views/Authentication/Auth.actions";
import { loginUserAction, googleUserData } from "../../Auth.actions";
// import OnBoardCarousel from "../../Components/OnBoardCarousel";
import OnBoardCarousel from "../../../../Components/OnBoardCarousel";
// import Loader from "../../Components/Loader";
import Loader from "../../../../Components/Loader";
import { message } from "antd";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
    type: "BUSINESS",
  });

  const { email, password, type } = loginForm;
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setLoginForm({
      ...loginForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (loginForm.email.trim() === "" || loginForm.password.trim() === "") {
      return message.error("Enter email and password.");
    }

    dispatch(loginUserAction(loginForm, navigate));
  };

  const [showPassword, setShowPassword] = useState(false);

  const passwordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const googleSuccessResponse = (res) => {
    console.log(res.credential);
    sessionStorage.setItem("googleToken", JSON.stringify(res.credential));
    dispatch(googleUserData());
  };


  return (
    <>
      {/* <header>
                <NavbarComp backgroundColor="#fff" color="#9099A3" hamColor="#9099A3" />
            </header> */}
      {/* <div className='login-container'> */}
      {/* <div className='login-image'>
                    <img src={assets.SignUpImage} alt="sign up" className='img-signup' />
                </div> */}
      {/* {isLoading ? (
                    <Loader />
                ) : ( */}
      <>
        {/* <div className='login-form-container'>
                            <h1> Admin Login</h1>
                            <div className='login-btns-div'> */}
        {/* <button onClick={handleGoogleLogin} className='login-btns'><img src={assets.google} alt='Google' /> Login with Google</button> */}
        {/* <GoogleAuthLogin
                                onSuccess={googleSuccessResponse}
                                onError={'error'}
                                /> */}
        {/* </div> */}
        {/* <p className='withEmail'><img src={assets.emailLine} alt='emailLine' /> with email Or Phone <img src={assets.emailLine} alt='emailLine' /></p> */}
        {/* <div className='input-form-container'>
                                <div className='input-form-container'>
                                    <input
                                        className='loginform-input'
                                        type='email'
                                        name='email'
                                        placeholder='Email or Phone Number'
                                        id='email'
                                        value={email}
                                        onChange={handleChange}
                                        autoComplete='off'
                                    />
                                    <input
                                        className='loginform-input'
                                        type={showPassword ? 'text' : 'password'}
                                        name='password'
                                        placeholder='Password'
                                        id='password'
                                        value={password}
                                        onChange={handleChange}
                                        autoComplete='off'
                                    />
                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className='visible-password-icon' onClick={passwordVisibility} />
                                </div>
                            </div> */}
        {/* <ToastContainer />
                            <div className='forget-password-div'>
                            </div>
                            <ToastContainer /> */}
        {/* <button className='otp-btn' onClick={handleLogin}>Login</button> */}

        {/* <p className='login-link'>Forget Password <a className='signinNavigationLink' onClick={() => navigate('/forget-password')}>Click here</a></p> */}
        {/* </div> */}
      </>
      {/* )} */}
      {/* </div> */}
      {/* <FooterComp /> */}
      <>
        <div className="login-main">
          <OnBoardCarousel />
          {isLoading ? (
            <Loader />
          ) : (
            <div className="login-form">
              <h1>Login</h1>
              <p>
                Login to your Nispand workspace and manage your organisation
              </p>
              <form>
                <div className="form-floating mb-3 mt-3">
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email or Phone Number"
                    value={email}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                  <label htmlFor="email" className="form-label">
                    Email Address *
                  </label>
                </div>
                <div className="form-floating mt-3 mb-3">
                  <input
                    className="form-control"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    id="password"
                    value={password}
                    onChange={handleChange}
                    autoComplete="off"
                  />

                  <label htmlFor="pwd" className="form-label">
                    Password *
                  </label>
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="visible-password-icon"
                    onClick={passwordVisibility}
                    style={{cursor: 'pointer'}}
                  />
                </div>
                <p>
                  Forget your password?{" "}
                  <Link
                    href="#"
                    className="link"
                    to={"/auth/setpassword"}
                    onClick={() => navigate("/setpassword")}
                  >
                    Click here
                  </Link>
                </p>
                <p>
                  New User?{" "}
                  <Link
                    href="#"
                    className="link"
                    to={"/auth/signup"}
                    onClick={() => navigate("/auth/signup")}
                  >
                    Click here
                  </Link>
                </p>
                {/* <ToastContainer /> */}
                <button className="btn-login" onClick={handleLogin}>
                  Login
                </button>
                <p className="mt-4">This portal works best on Desktop devices. Please use a PC/Desktop to access.</p>
              </form>
            </div>
          )}
        </div>
      </>
    </>
  );
};

export default Login;
