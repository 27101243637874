import React, { useState, useEffect } from 'react'
import SetPassword from '../SetPassword';
import { setPasswordForLogin } from '../../Constants/Api';
import { alertMsg } from '../../../../Components/DecesionComponent/toastifyAlert';
import Loader from '../../../../Components/Loader';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';

const ResetPasswordModal = ({ formData, otp, isLoading, setIsLoading}) => {

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isCapital, setIsCapital] = useState(false);
    const [isSmall, setIsSmall] = useState(false);
    const [isNumber, setIsNumber] = useState(false);
    const [characterCount, setCharacterCount] = useState(0);
    const [editedData, setEditedData] = useState({})
    const [isDisabled, setIsDisabled] = useState(true);

    const navigate = useNavigate();
    
    let debounce
    const handlePasswordChange = (e) => {
        const updatedPassword = e.target.value;
        setEditedData({
            ...editedData,
            [e.target.name]: e.target.value
        })
        clearTimeout(debounce)
        debounce = setTimeout(
            isFieldsChanged({
                ...isCapital,
                ...isSmall,
                ...characterCount,
                ...isNumber,
                ...editedData,
                [e.target.name]: e.target.value
            }),
            500
        )
        setPassword(updatedPassword);
        setIsCapital(/[A-Z]/.test(updatedPassword));
        setIsSmall(/[a-z]/.test(updatedPassword));
        setIsNumber(/[0-9]/.test(updatedPassword));
        const count = updatedPassword.replace(/\s/g, '').length;
        setCharacterCount(count);
    };

    const handleConfirmPasswordChange = (e) => {
        const ConfirmedPassword = e.target.value;
        setConfirmPassword(ConfirmedPassword);
        setIsCapital(/[A-Z]/.test(ConfirmedPassword));
        setIsSmall(/[a-z]/.test(ConfirmedPassword));
        setIsNumber(/[0-9]/.test(ConfirmedPassword));
        const count = confirmPassword.replace(/\s/g, '').length;
        setCharacterCount(count);
    };

    const isFieldsChanged = () => {
        if (password === confirmPassword) return false 
        else return true
    }

    useEffect(() => {
        console.log(password, confirmPassword)
        if (password === confirmPassword && characterCount >= 8 && isCapital && isSmall && isNumber) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [characterCount, isCapital, isNumber, isSmall]);

    const handleSubmit = async (e) => {
        e.preventDefault()
        // setIsLoading(true) 
        try {
            await setPasswordForLogin({
                newPassword: password,
                email : formData?.email,
                otp: otp,
                type: 'BUSINESS'
            })
            // setIsLoading(false)
        } catch (error) {
            message.error("error");
        }
        navigate("/auth/login")
    }

    return (<>
                {isLoading ? <Loader /> : <SetPassword
                    handlePasswordChange={handlePasswordChange}
                    handleConfirmPasswordChange={handleConfirmPasswordChange}
                    handleSubmit={handleSubmit}
                    characterCount={characterCount}
                    password={password}
                    confirmPassword={confirmPassword}
                    isCapital={isCapital}
                    isNumber={isNumber}
                    isSmall={isSmall}
                    isDisabled={isDisabled}
                />}
    </>)
}

export default ResetPasswordModal
