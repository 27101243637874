// import OSS from "ali-oss";
import { getSecureStsCred, regenerateSecureStsToken } from "./signup.upload.API";
import axios from "axios";
import AWS from 'aws-sdk';

const getOssClient = ({
  securityToken,
  accessKeyId,
  accessKeySecret,
  ossBucketName,
  ossBucketRegion,
}) => {
  // const client = new OSS({
  //   region: ossBucketRegion,
  //   accessKeyId,
  //   accessKeySecret,
  //   stsToken: securityToken,
  //   bucket: ossBucketName,
  //   // Refresh the temporary access credential.
  //   refreshSTSToken: async () => {
  //     const { data } = await regenerateSecureStsToken();
  //     return {
  //       accessKeyId: data.data.accessKeyId,
  //       accessKeySecret: data.data.accessKeySecret,
  //       stsToken: data.data.securityToken,
  //     };
  //   },
  // });

  // return client;
};

export const formatMultiSelectData = (data) => {
  const formatedData = data.map(({ value }) => value);
  return formatedData;
};

// AUDIO_IMAGE
// export const signupUploadFile = (file, type, onProgress, percentageW) =>{
//   ; 
//   return new Promise(async (resolve, reject) => {
//     try {
//       let uploadFile = file;
//       const token = sessionStorage.getItem("signUpToken"); 
//     const { data } = await axios.get(
//       `https://uat.nispand.com//v2/media/secure/sts/cred`,
//       {
//         params: {
//           name: uploadFile.name,
//           type: type,
//         },
//         headers: {
//           Authorization: `Bearer ${token}`, 
//         },
//       }
//     );

//       const client = getOssClient(data.data);


//       const { name, bucket } = await client.multipartUpload(
//         data.data.fileLocation,
//         uploadFile,
//         {
//           progress: (p) => {
//             onProgress(p * percentageW);
//           },
//           partSize: 1024 * 1024 * 1, 
//           parallel: 3,
//         }
//       );
//       resolve(
//         `https://${bucket}.${data.data.ossBucketRegion}.aliyuncs.com/${name}`
//       );
//     } catch (error) {
//       reject({
//         error: true,
//         errorMessage: "Unable to upload data try again later",
//       });
//     }
//   });
// }

export const signupUploadFile = (file, type, onProgress, percentageW) => {

  return new Promise(async (resolve, reject) => {
    try {
      let uploadFile = file;
      const token = sessionStorage.getItem("signUpToken");
      const { data } = await axios.get(
        `https://back-end-preprod.nispand.com/v2/media/secure/sts/cred`,
        {
          params: {
            name: uploadFile.name,
            type: type,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // const client = getOssClient(data.data);
      let bucketDetails = data?.data
      const s3 = new AWS.S3({
        accessKeyId: bucketDetails?.accessKeyId,
        secretAccessKey: bucketDetails?.accessKeySecret,
        region: bucketDetails?.ossBucketRegion,
      });


      const params = {
        Bucket: bucketDetails?.ossBucketName,
        Key: bucketDetails?.fileLocation,//file.name,
        Body: file,
        ContentType: type,
        ContentLength: file.size,
      };
      const options = {
        partSize: 1024 * 1024 * 5, // 1MB chunks
        queueSize: 3, // 3 parallel uploads
      };

      // Create the upload and track progress
      const upload = s3.upload(params, options, (error, data) => {
        if (error) {
          console.error("Upload error:", error); // Log the error
          return reject({
            error: true,
            errorMessage: "Unable to upload data, try again later",
          });
        }

        console.log("Upload successful:", data); // Log the success response
        resolve(data.Location); // URL of the uploaded file
      });

      upload.on('httpUploadProgress', (progress) => {
        console.log(`Upload progress: ${progress.loaded} of ${progress.total} bytes`); // Log upload progress
        onProgress(progress.loaded / progress.total * percentageW);
      });
    } catch (error) {
      reject({
        error: true,
        errorMessage: "Unable to upload data try again later",
      });
    }
  });
}


