import React, { useState } from "react";
import { Outlet } from "react-router-dom";

const Layout = () => {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);

//   const checkUserToken = () => {
//     const userToken = localStorage.getItem("user-token");
//     if (!userToken || userToken === "undefined") {
//       setIsLoggedIn(false);
//     }
//     setIsLoggedIn(true);
//   };

//   useEffect(() => {
//     checkUserToken();
//   }, [isLoggedIn]);

  return (
    <>
      <Outlet />
    </>
  );
};

export default Layout;
