import axios from "axios";
import { authBaseDomain } from "../../Constants/authApis";
import { stage } from "../../Constants/authApis";
// import { BaseDomain } from "../../../Constants/api/appSettings";

export const logInApi = (payload) => {
    return axios.post(`${authBaseDomain}${stage}/auth/login`, payload)
}

export const fetchUserDataAPI = () => {
    return axios.get(`${authBaseDomain}${stage}/auth/me`)
    // return axios.get(`${authBaseDomain}${stage}/auth/me`)
}


export const googleLoginApi = (keyId) => {
    return console.log('googleloginapi')
    // axios.post(`${authBaseDomain}/auth/googleLogin/${keyId}`)
}