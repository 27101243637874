import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { Label, FormGroup, Alert } from "reactstrap";
// import getColors from "get-image-colors";
import { getImgRatioHvW } from "./FileUploader";
import formatBytes from "../../Utils/formatBytes";
const FileUploader = ({
  file,
  setFile,
  accept = "",
  isMultiple = false,
  dragText = "+ Upload logo",
  heading,
  setImageColor,
  imageColor,
  isDisabled = false,
  isImage,
  imgRatioRangeLow,
  imgRatioRangeHigh,
  errMsg,
}) => {
  const [imgSizeError, setImgSizeError] = useState({
    isImgSizeError: false,
    imgSizeErrorMsg: "",
  });
  const { isImgSizeError, imgSizeErrorMsg } = imgSizeError;
  const handleAcceptedFiles = async (acceptedFile) => {
    try {
      const fileTemp = acceptedFile[0];
      const fileUrl = URL.createObjectURL(fileTemp);
      const file = Object.assign(fileTemp, {
        preview: fileUrl,
        formattedSize: formatBytes(fileTemp.size),
      });
      if (isImgSizeError)
        setImgSizeError({
          isImgSizeError: false,
          imgSizeErrorMsg: "",
        });
      if (isImage) {
        const dim = await getImgRatioHvW(file.preview);
        if (dim > imgRatioRangeLow && dim < imgRatioRangeHigh){
          if (setImageColor){
            // const colors = await getColors(fileUrl);
            // setImageColor(colors[0].hex());
          }
          return setFile(file);
        } else
          return setImgSizeError ({
            isImgSizeError: true,
            imgSizeErrorMsg: errMsg,
          });
      }
      setFile(file);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <FormGroup className="select2-container mt-3">
      <Label className="control-label">{heading}</Label>
      <Dropzone
        onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
        multiple={isMultiple}
        accept={accept}
        disabled={isDisabled}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            className={`dropzone ${isImgSizeError && "border-danger"}`}
            style={{background: "#EAEFFA" ,cursor:"pointer" }}
          >
            <div className="needsclick" {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="dropzone-previews" id="file-previews">
                {file ? (
                  <div className="d-flex justify-content-center align-items-center " >
                    {typeof file === "string" && /\.(jpg|jpeg|png|webp|avif|gif|svg|pdf|jfif)$/.test(file) ? (
                      <img
                        data-dz-thumbnail=""
                        height="180"
                        className="bg-light"
                        alt={file}
                        src={file}
                        style={{
                          background: "linear-gradient(to bottom, #eee, #ddd)",
                          borderRadius: "20px",
                          cursor:"pointer",
                          maxHeight: "15rem"
                        }}
                      />
                    ) : (
                      <>
                        {file?.type?.includes("image") ? (
                          <img
                            data-dz-thumbnail=""
                            height="180"
                            className="bg-light"
                            alt={file.name}
                            src={file.preview}
                            style={{
                              background:
                                "linear-gradient(to bottom, #eee, #ddd)",
                              borderRadius: "20px",
                              cursor:"pointer",
                              maxHeight: '15rem'
                            }}
                          />
                        ) : (
                          <div
                            className="d-flex py-4 align-items-center flex-column"
                            style={{
                              height: "180px",
                              minWidth: "180px",
                              background:
                                "linear-gradient(to bottom, #eee, #ddd)",
                              borderRadius: "20px",
                              padding: "0 10px",
                              cursor:"pointer"
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "rgba(255,255,255,0.4)",
                                borderRadius: "3px",
                                fontSize: "1rem",
                                fontWeight: "bold",
                                marginTop: "1.2rem",
                                padding: "2px 6px",
                                cursor:"pointer"
                              }}
                            >
                              {file.formattedSize}
                            </div>
                            <div
                              style={{
                                backgroundColor: "rgba(255,255,255,0.4)",
                                borderRadius: "3px",
                                marginTop: "1rem",
                                padding: "2px 6px",
                                cursor:"pointer"
                              }}
                            >
                              {file.name}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center flex-column pb-2.5 " style={{cursor:"pointer"}} >
                    <i className="display-4 text-muted ri-upload-cloud-2-line mt-4"></i>
                    <h6>{dragText}<span style={{ color: "#FF5733" }}>*</span> </h6>
                    {isImgSizeError && (
                      <Alert color="danger">{imgSizeErrorMsg}</Alert>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Dropzone>
    </FormGroup>
  );
};
export default FileUploader;