import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isAuthorized } from "./localStorage";

const ProtectedRoute = ({children}) => {
  // console.log(children, "jkkkk");
  const navigate = useNavigate();

  // const [isLoggedIn, setIsLoggedIn] = useState(false);

    // const checkUserToken = () => {
    //   const userToken = localStorage.getItem("user-token");
    //   if (!userToken || userToken === "undefined") {
    //     setIsLoggedIn(false);
    //     return navigate("/auth/login");
    //   }
    //   setIsLoggedIn(true);
    // };

  useEffect(() => {
    // checkUserToken();
    if (!isAuthorized()) {
      navigate("/auth/login");
    }
  }, []);


  return <>{isAuthorized() ? children : null}</>;
};

export default ProtectedRoute;
