import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import "./styles.scss";
import { nispandLogo, home, box, card, help, settings, logout } from "../../../../Assets/icons";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { clearLocal } from "../../../../Utils/localStorage";
import { User } from "@nextui-org/react";
import { Modal } from "antd";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selector = useSelector((state) => state?.auth?.user?.data);
  
  // const [imgAsset, setImgAsset] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showLogoutModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
    clearLocal();
    navigate("/auth/login");
  };

  // const handleLogout = () => {
  //   clearLocal();
  //   navigate("/auth/login");
  // };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // useEffect(() => {
  //   setImgAsset(selector?.businessLogo)
  // }, [selector])

  const listClass = "p-4 d-flex gap-3 align-items-center rounded-2 text-decoration-none text-dark cursor-pointer width-full";
  const activeClass = "bg-#274da5 text-white";

  return (
    <div className="sidebar">
      <Container className="sidebar-container h-100 d-lg-flex flex-wrap align-content-between d-none">
        <Nav variant="pills">
          <div className="text-center my-5 w-100">{nispandLogo}</div>
          <Nav.Item className="w-100">
            <NavLink to="/dashboard/home" className={`${listClass} ${location.pathname === '/dashboard/home' ? activeClass : ''}`}>
              {home}
              Home
            </NavLink>
          </Nav.Item>
          <Nav.Item className="w-100">
            <NavLink to="/dashboard/members" className={`${listClass} ${location.pathname === '/dashboard/members' || location.pathname === '/dashboard/members/accounts' ? activeClass : ''}`}>
              {box}
              Members
            </NavLink>
          </Nav.Item>
          <Nav.Item className="w-100">
            <NavLink to="/dashboard/billing" className={`${listClass} ${location.pathname === '/dashboard/billing' || location.pathname === '/dashboard/billing/add-licences' ? activeClass : ''}`}>
              {card}
              Billing and Payments
            </NavLink>
          </Nav.Item>
        </Nav>
        <Nav>
          <Nav.Item className="w-100">
            <NavLink to="/dashboard/help" className={`${listClass} ${location.pathname === '/dashboard/help' ? activeClass : ''}`}>
              {help}
              Help
            </NavLink>
          </Nav.Item>
          <Nav.Item className="w-100">
            <NavLink to="/dashboard/settings" className={`${listClass} ${location.pathname === '/dashboard/settings' ? activeClass : ''}`}>
              {settings}
              Settings
            </NavLink>
          </Nav.Item>
          <Nav.Item className="w-100">
            <div
              style={{ cursor: "pointer" }}
              className={`${listClass} px-2 flex-wrap justify-content-center`}

            >
              <div className="avatar rounded-circle fw-bold d-flex align-items-center">
                {/* Display business logo */}
                <img src={selector?.businessLogo} alt="logo" />
              </div>
              <div>
                <div className="fw-bold mb-1">{selector?.firstName} {selector?.lastName}</div>
                <div className="email">{selector?.email}</div>
              </div>

              <div onClick={()=>showLogoutModal()}>

              {logout}

              </div>
            </div>
          </Nav.Item>
        </Nav>
      </Container>
      <Navbar expand="lg" className="d-lg-none">
        <Container fluid className="sidebar-container py-5 px-2 px-sm-5 ">
          <Navbar.Brand onClick={() => navigate("/overview")}>
            {nispandLogo}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mt-3">
              <Nav.Item className="w-100">
                <NavLink to="/dashboard/home" className={listClass}>
                  {home}
                  Reporting
                </NavLink>
              </Nav.Item>
              <Nav.Item className="w-100">
                <NavLink to="/dashboard/members" className={listClass}>
                  {box}
                  Members
                </NavLink>
              </Nav.Item>
              <Nav.Item className="w-100">
                <NavLink to="/dashboard/billing" className={listClass}>
                  {card}
                  Billing and Payments
                </NavLink>
              </Nav.Item>
              <Nav.Item className="w-100">
                <NavLink to="/dashboard/help" className={listClass}>
                  {help}
                  Help
                </NavLink>
              </Nav.Item>
              <Nav.Item className="w-100">
                <NavLink to="/dashboard/settings" className={listClass}>
                  {settings}
                  Settings
                </NavLink>
              </Nav.Item>
              <Nav.Item className="w-100">
                <div className={listClass} id="listClass" >
                  <div className="avatar rounded-circle p-3 fw-bold d-flex align-items-center">

                   <User
                     name={selector?.firstName + "" + selector?.lastName}
                      description={selector?.email}
                      avatarProps={{
                        src: nispandLogo
                      }}
                    />
                  </div>
                  {logout}
                </div>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {isModalVisible?<Modal
        title="Logout Confirmation"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
        centered
      >
        <p>Are you sure you want to logout?</p>
      </Modal>:null}
    </div>
  );
};

export default Sidebar;
