import { call, put } from "redux-saga/effects";
import { setLocal, clearLocal } from "../../Utils/localStorage";
import { useNavigate } from "react-router-dom";


import setDefaultHeaders from "../../Utils/setDefaultHeaders";
import {
  loginUserError,
  loginUserSuccess,
  logoutUserAction,
  fetchUserData
} from "./Auth.actions";
import { googleLoginApi, logInApi } from "./Auth.api";
import { fetchUserDataAPI } from "./Auth.api";
import { message } from "antd";
import { ToastContainer, toast } from 'react-toastify';

export function* handleLoginUserEffect({ payload, navigator }) {
  window.addEventListener('popstate', () => {
    clearLocal("authToken");
    setDefaultHeaders("authorization", null);
  });
  try {
    const { data } = yield call(logInApi, payload);
    if (!data.token) {
      message.error("Dispatch Error");
      return;
    }
    setLocal("authToken", data.token);
    setDefaultHeaders("authorization", `Bearer ${data?.token}`);
    yield put(loginUserSuccess());
    yield put(fetchUserData())
    if (navigator) {
      navigator("/dashboard/home");
      // window.location.reload()
    }
  } catch (error) {
    if(error.response.data.error=="User not found with this email"){
      toast.error("User not found, Sign up to create your account")
      // yield put(loginUserError(error.response.data.error));
    }
    else if(error.response.data.error=="Invalid Credentials"){
      toast.error("Invalid Credentials.")
      yield put(loginUserError(error.response.data.error));
    }
    else{
    console.log("error", error.response.data.error);
    yield put(loginUserError(error.response.data.error));
    }
  }
}

export function* handleGoogleLoginEffect() {
  try {
    const googleCred = JSON.parse( sessionStorage.getItem("googleToken"))
    const { data } = yield call(googleLoginApi(googleCred));
    console.log(data,'googlecred');
    // if (!data.token) {
    //   console.log("Dispatch Error");
    //   return;
    // }
    // setLocal("authToken", data.token);
    // setDefaultHeaders("authorization", `Bearer ${data.accessToken}`);
    // yield put(loginUserSuccess());
    // yield put(fetchUserData())
    // if (navigator) {
    //   navigator("/");
    // }
  } catch (error) {
    console.log(error,'error');
  }
}

export function handleLogoutUserEffect() {
  setDefaultHeaders("authorization");
  clearLocal();
}

export function* handleFetchUserDataEffect() {
  try {
    const { data } = yield call(fetchUserDataAPI);
    yield put(loginUserSuccess(data));
  } catch (error) {
    yield put(logoutUserAction());
  }
}

