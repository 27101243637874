export const authActions = {
  LOGIN_USER: "auth/LOGIN_USER",
  LOGOUT_USER: "auth/LOGOUT_USER",
  LOGIN_USER_SUCCESS:"auth/LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILURE:"auth/LOGIN_USER_FAILURE",
  FETCH_USER_DATA:"auth/FETCH_USER_DATA",
  SET_GOOGLE_USER_DATA : "auth/SET_GOOGLE_USER_DATA"
  
};

export const loginUserAction = (payload, navigator) => {
    return {
      type: authActions.LOGIN_USER,
      payload,
      navigator,
    }
  }

  export const loginUserSuccess = (data) => ({
    type: authActions.LOGIN_USER_SUCCESS,
    payload:data
  }
 
  )
  
  export const logoutUserAction = () => ({
    type: authActions.LOGOUT_USER,
  })

  export const loginUserError=(data)=>({
    type:authActions.LOGIN_USER_FAILURE,
    payload:data
  })

  export const fetchUserData=()=>({
    type:authActions.FETCH_USER_DATA,

  })

  export const googleUserData = (payload, navigator) =>({
    type : authActions.SET_GOOGLE_USER_DATA,
    // payload,
    navigator,
  })  